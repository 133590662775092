/* Header */
.cus-navitem {
    margin: 6px;
}

.navbar {
    background-color: #eee;
    /* overflow: hidden; */

}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: -106px;
    margin-top: .125rem;
}

.dropdown-menu {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.466);

}

.user-profile {
    /* display: flex;
    align-items: center;
    justify-content: center; */
    position: relative;
    top: 0;
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.user-profile img {
    background-color: #b0c3da;
    /* padding: 10px; */
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* color: rgba(0, 0, 0, 0.582); */


}

.user-profile-h {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.user-profile-h i {
    background-color: #b0c3da;
    padding: 10px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.582);

}

@media screen and (max-width:600px) {
    .dropdown-menu {
        top: 100%;
        left: -140px !important;
        /* left: 0 !important; */
        margin-top: .125rem;
    }

    .dropdown-menu[data-bs-popper] {
        top: 100%;
        left: 0 !important;
        margin-top: 0.125rem;
    }
}

.navbar-nav .cus-navitem .nav-link {
    color: rgb(94, 94, 94);
    transition: all 0.3s ease-in-out;
    border-bottom: 1px solid transparent;

}

.navbar-nav .cus-navitem .nav-link:hover {
    color: #0eb7d1;
    border-bottom: 1px solid rgb(158, 158, 158);
}

.cus-log-btn {
    padding: 4px 25px;
    background-color: #0eb7d1;
    color: #fff;
    border: 0;
    border-radius: 5px;
    height: 33px;
    margin-right: 10px;
}

.cus-log-btn:hover {
    background-color: #0eb7d1d7;
    color: #fff;
}

.navbar-nav .cus-navitem .nav-link+.active {
    color: #0eb7d1;
    border-bottom: 1px solid rgb(158, 158, 158);
}

.dropdown-item:focus,
.dropdown-item:hover {
    color: #ffffff;
    background-color: #0eb7d1;
    border-radius: 0px;
}


.btn-check:focus+.btn,
.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, .25);
    color: #ffff;
}

.cus-header-drop {
    background-color: rgb(226, 226, 226);
}

.disabled {
    pointer-events: none;
}



/* HeaderEnd */


/* MainSection */
.main-container {
    position: relative;
    width: 100%;
    min-height: 50vh;
}

/* MainSectionEnd */

/* footer */
h1,
h2,
h3,
h4,
h5,
h6 {}

section {
    padding: 60px 0;
    min-height: 100vh;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}

a,
a:active,
a:focus {
    color: #6f6f6f;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}

ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

img {
    max-width: 100%;
    height: auto;
}

.footer-section {
    background-color: #233243;
    position: relative;
    overflow: hidden;
    z-index: 9;
}

.footer-section:before {
    content: '';
    position: absolute;
    top: -146%;
    left: -18%;
    width: 44%;
    height: 257%;
    transform: rotate(54deg);
    background-color: rgb(31, 47, 64);
    -webkit-transform: rotate(54deg);
    -moz-transform: rotate(54deg);
    -ms-transform: rotate(54deg);
    -o-transform: rotate(54deg);
    z-index: -10;
}

.footer-section:after {
    position: absolute;
    content: '';
    background-color: rgb(31, 47, 64);
    top: -24%;
    right: 4%;
    width: 26%;
    height: 264%;
    transform: rotate(44deg);
    -webkit-transform: rotate(44deg);
    -moz-transform: rotate(44deg);
    -ms-transform: rotate(44deg);
    -o-transform: rotate(44deg);
    z-index: -10;
}

.footer-top {
    padding-top: 96px;
    padding-bottom: 50px;
}

.footer-top p,
.company-footer-contact-list li {
    color: #ffffff;
}

.company-footer-contact-list {
    margin-top: 10px;
}

.company-footer-contact-list li {
    display: flex;
    display: -webkit-flex;
    align-items: center;
}

.company-footer-contact-list li+li {
    margin-top: 5px;
}

.company-footer-contact-list li i {
    margin-right: 10px;
    font-size: 20px;
    display: inline-block;
}

.footer-top .site-logo {
    margin-bottom: 25px;
    display: block;
    max-width: 170px;
}

.widget-title {
    text-transform: capitalize;
}

.footer-top .widget-title {
    color: #ffffff;
    margin-bottom: 40px;
}

.courses-link-list li+li {
    margin-top: 10px;
}

.courses-link-list li a {
    color: #ffffff;
    text-transform: capitalize;
    font-family: var(--para-font);
    font-weight: 400;
}

.courses-link-list li a:hover {
    color: #ffb606;
}

.courses-link-list li i {
    margin-right: 5px;
}

.footer-top .small-post-title a {
    font-family: var(--para-font);
    color: #ffffff;
    font-weight: 400;
}

.small-post-item .post-date {
    color: #ffb606;
    margin-bottom: 3px;
    font-family: var(--para-font);
    font-weight: 400;
}

.small-post-list li+li {
    margin-top: 30px;
}

.news-letter-form {
    margin-top: 15px;
}

.news-letter-form input {
    width: 100%;
    padding: 12px 25px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    border: none;
}

.news-letter-form input[type="submit"] {
    width: auto;
    border: none;
    background-color: #ffb606;
    padding: 9px 30px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
    color: #ffffff;
    margin-top: 10px;
}

.footer-bottom {
    padding: 13px 0;
    border-top: 1px solid rgba(255, 255, 255, 0.149);
}

.copy-right-text {
    color: #ffffff;
}

.copy-right-text a {
    color: #ffb606;
}

.terms-privacy li+li {
    margin-left: 30px;
}

.terms-privacy li a {
    color: #ffffff;
    position: relative;
}

.terms-privacy li a:after {
    position: absolute;
    content: '-';
    color: #ffffff;
    display: inline-block;
    top: 0;
    right: -18px;
}

.terms-privacy li+li a:after {
    display: none;
}

/* footerEnd */


/* 
 mobile sidebar */

.cus-offcanvas {
    width: 250px;
}

.user-box {
    background-color: #f7f6f6;
    padding: 9px 0px;
    text-align: center;
}

.user-box p {
    margin-bottom: 0;
}

.user-box img {
    width: 25px;
    height: 25px;
    border-radius: 50px;

}

.side-nav {
    border-radius: 3px;
    color: #2e2e2e !important;
}

.side-nav a {
    color: #2e2e2e;
}

.side-nav:hover {
    background-color: #0eb7d1;
    color: #fff !important;
    border-radius: 3px;
}

.side-nav:hover a {
    color: #fff !important;
}

.side-nav a:focus {
    color: #fff !important;
}

.backbtn {
    background-color: #0eb7d1;
    color: #fff;
    width: 40px;
    height: 40px;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebard-btnbox {
    text-align: center;
}

.cus-btn {
    padding: 4px 15px;
    background-color: #0eb7d1;
    color: #fff;
    margin: 10px;
    /* border: 0;
    border-radius: 5px; */
}

.cus-btn:focus {
    box-shadow: none;
}

.massage-box {
    display: flex;
    align-items: center;
    margin: 0px 5px;
    width: 40px;
    height: 40px;
    background-color: #0eb7d1;
    border-radius: 50px;
    justify-content: center;
    color: #fff;
}