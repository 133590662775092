* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}

.depositbg {
    background-color: rgb(25 25 25);
    min-height: 70vh;
    position: relative;
}

.deposit-box {
    background-color: #eee;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    padding: 40px 20px;
    border-radius: 2px;
}

.deposit-box h3 {
    text-align: center;
    color: #8c8c8c;
    /* padding: 30px 0px; */
}

.cus-formcontrol:focus {
    box-shadow: none;
    border-color: rgb(175, 175, 175);
}

.form-btn {
    color: #fff;
    background-color: #0eb7d1;
}

.form-btn:hover {
    color: #fff;
    background-color: #0eb7d1ef;
}

.cusnav-tab {
    justify-content: center;
    /* width: 50%; */
}

.casnav-link {
    width: 100% !important;

}

.cus-font-r {
    font-size: 13px;
}

.cus-btn-copy {
    background-color: #0eb7d1;
    color: #fff;
    cursor: pointer;
}

/* barcodebox */
.imgdeposit {
    text-align: center;
}

.imgdeposit img {
    width: 200px;
    height: 200px;
    margin: auto;
}

/* media qaury/ */

@media screen and (max-width:600px) {
    .deposit-box {
        background-color: #eee;
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        padding: 40px 20px;
        border-radius: 2px;
    }
}