 * {
     margin: 0;
     padding: 0;
     box-sizing: border-box;
 }

 .nav {
     display: flex;
     flex-wrap: wrap;
     padding-left: 0;
     margin-bottom: 0;
     justify-content: space-around;
     list-style: none;
 }

 .nav-tabs .nav-item {
     flex: 1 1 auto;
 }

 .nav-tabs .nav-link.active {
     width: 100%;
     color: #fff !important;
     background-color: #0eb7d1;
     border-radius: 5px;
     border: 0;
 }

 .nav-tabs .nav-link {
     border: 0;
     margin: auto;
 }

 .nav-tabs {
     border-bottom: 0px;
 }

 .cus-log-btn {
     padding: 4px 10px;
     background-color: #0eb7d1;
     color: #fff;
     border: 0;
     border-radius: 5px;
     height: auto;
     margin-right: 10px;
     width: 100%;
 }

 .cus-log-btn:hover {
     background-color: #0eb7d1d7;
     color: #fff;
 }

 .spanfont {
     font-weight: 700;
 }

 .td-padding {
     padding: 20px !important;
 }

 .th-padding {
     padding: 20px !important;
 }

 tr {
     padding: 15px !important;
     transition: all 0.3s;
     cursor: pointer;
 }

 .logo-img {
     display: flex;
     align-items: center;
 }

 .tbl-logo img {
     width: 20px;
     height: 20px;
     margin: 0px 5px;
 }

 .red {
     color: red;
 }

 .green {
     color: green;
 }

 .table {
     white-space: nowrap;
 }


 .menubtn-box i {
     margin: 0px 3px;
     /* display: block; */
     padding: 2px;
 }


 .menunavbox {
     background-color: #0eb7d1;
     display: flex;
     align-items: center;
     /* padding: 10px 0px; */
     padding-top: 5px;
     border-top-left-radius: 20px;
     border-top-right-radius: 20px;
     z-index: 999;


 }

 .navlinbtn {
     color: #0eb7d1;
     text-align: center;
     background-color: aliceblue;
     display: inline-block;
     margin: 0px 5px;
     width: 20%;
     /* height: 70px; */
     border-radius: 5px;


 }

 .navlinbtn i {
     font-size: 20px;
 }

 .navlinbtn p {
     margin-bottom: 0;
     font-size: 12px;
 }

 .shadow-effect img {
     width: 300px;
     height: 200px;
     /* object-fit: cover; */
     background-size: cover;
     background-repeat: no-repeat;
     background-position: center;
     border-radius: 20px;

 }


 .ovralay {
     position: absolute;
     top: 0;
     left: 0;
     background-color: #000000b5;
     z-index: 1;
     width: 100%;
     height: 100%;
 }

 .caption-box {
     position: relative;
     top: 0;
     z-index: 100;
     background-color: #c5c3c12b;
     color: #fff;
     font-weight: 600;
 }

 .price-card {
     /* position: relative; */

     width: 100%;
     height: 150px;
     background-image: url('/public/pricecard1.jpg');
     /* background-color: #0eb7d1; */
     margin-bottom: 10px;
     overflow: hidden;
     background-position: center;
     background-size: cover;
     display: flex;

     justify-content: center;
     border-radius: 10px;

 }

 .price-cardtwo {
     border-radius: 10px;
     width: 100%;
     height: 150px;
     background-image: url('/public/pricecard2.jpg');
     /* background-color: #0eb7d1; */
     margin-bottom: 10px;
     overflow: hidden;
     background-position: center;
     background-size: cover;
     display: flex;

     justify-content: center;
 }

 .price-cardthree {
     border-radius: 10px;
     width: 100%;
     height: 150px;
     background-image: url('/public/pricecard3.jpg');
     /* background-color: #0eb7d1; */
     margin-bottom: 10px;
     overflow: hidden;
     background-position: center;
     background-size: cover;
     display: flex;

     justify-content: center;
 }

 .price-cardfour {
     border-radius: 10px;
     width: 100%;
     height: 150px;
     background-image: url('/public/pricecard4.jpg');
     /* background-color: #0eb7d1; */
     margin-bottom: 10px;
     overflow: hidden;
     background-position: center;
     background-size: cover;
     display: flex;

     justify-content: center;
 }

 .caption-box {
     /* display: flex;
    align-items: center; */
 }

 .price-logo {
     background-color: transparent;
 }

 .price-logo img {
     width: 25px;
     height: 25px;
 }



 @media only screen and (max-width: 700px) {
     .menunavbox {
         background-color: #000;
         position: fixed;
         background-attachment: fixed;
         bottom: 0;
         z-index: 999;
         left: 0;
         width: 100%;
         height:70px;
     }

     .navlinbtn {
         color: #fff;
         text-align: center;
         background-color: transparent;
         display: inline-block;
         margin: 0px 5px;
         width: 20%;
         /* height: 50px; */
         border-radius: 5px;


     }

     .navlinbtn i {
         font-size: 20px;
         /* background-color: #b4b4b4; */

     }

     .iconnavbtn {
         width: 36px;
         height: 36px;
         background-color: #fff;
         color: #000000;
         border-radius: 50px;
         display: flex;
         align-items: center;
         justify-content: center;
         margin: auto;
     }

     .iconnavbtn:hover {
         background-color: #ffffffcb;
     }


 }