.ref-heading-box {
    margin: 30px 0px;
    /* text-align: center; */
}

.copy-box {
    display: flex;
    align-items: center;
}

.copy-box p {
    color: rgb(65, 65, 192);
    margin: 0px 10px;
    cursor: pointer;
    display: flex;
    align-items: center;

    /* border: 1px solid gray;
    padding: 5px; */
}

.copy-btn {
    /* width:40px;
    height:40px; */
    background-color: #0eb7d1 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50px;
    cursor: pointer;
}

.ref-card {
    border: 0;
    background-color: #ededed4f;
}

.nav-tabs .tab-cus-nav {
    border: 0 !important;
    background-color: transparent !important;

}

.nav-tabs .tab-link {
    color: #9f9f9f !important;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
    /* border-color: #e9ecef #e9ecef #dee2e6;
    isolation: isolate; */
    /* border: 0 !important;; */
    box-shadow: none;
    border-color: transparent;
}


.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
    color: #535353 !important;
    background-color: transparent;
    border-color: transparent;
    border-bottom: 1px solid #535353;
    /* border-radius: 5px; */
}

.raf-within-card {
    padding: 10px 0px;
    text-align: center;
    margin-bottom: 10px;
}

.raf-within-card p:nth-child(1) {
    color: rgb(10, 10, 10);
}

.raf-within-card p:nth-child(2) {
    color: rgb(72, 72, 72);
}

.t-head-ref {
    background-color: #f7f7f7;
    color: #343232c4;
}

.table-box-ref {
    width: 100%;
    height: 300px;
    overflow: auto;

}

@media screen and (max-width: 600px) {
    .copy-box p {
        font-size: 12px;

    }

    .copy-btn {

        font-size: 12px;
    }

    .nav-tabs .tab-cus-nav {
        border: 0 !important;
        background-color: transparent !important;
        font-size: 10px;
        margin: auto;
    }

    .nav-tabs .tab-link {
        color: #9f9f9f !important;
        padding: 6px;
    }
}