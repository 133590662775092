.main {
    background-color: #192635;
    color: #fff;
}

.main-cus {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    padding: 10px 0px;
}

.market-nav p:nth-child(1) {
    color: #fff;
    font-weight: 500;
    font-size: 17px;
    text-transform: capitalize;
}

.market-nav p:nth-child(2) {
    color: #bbbbbb;
    text-align: center;
}

.history-tbl {
    width: 100%;
    height: 250px;
    /* overflow: scroll; */
}

.market-nav>p {}

.market-nav {
    padding-right: 20px;
}

.overflow-div {
    overflow: auto;
}

/* tablecoin css */
.spanfont {
    font-weight: 700;
}

.td-padding {
    padding: 20px !important;
}

.th-padding {
    padding: 20px !important;
}

/* td {
    padding: 20px !important;
} */

tr {
    padding: 15px !important;
    transition: all 0.3s;
    cursor: pointer;
}

.logo-img {
    display: flex;
    align-items: center;
}

.tbl-logo img {
    width: 20px;
    height: 20px;
    margin: 0px 5px;
}

.table {
    white-space: nowrap;
    cursor: pointer;
    font-size: 15px;
}

.stock-tbl {
    border: 1px solid #fff;
}

.history-card-body {
    width: 100%;
    overflow: auto;
}

.history-card {
    margin: 20px 0px;
}

.chart-box {
    background-color: #222222;
    border-radius: 3px;
    padding: 10px;
}


/* bytab */

.nav-pills .cus-navlink {
    width: 100%;
    background-color: rgb(255, 255, 255);
    color: #0eb7d1;
    border-radius: 0;
    font-size: 16px;
    font-weight: 600;
}

.cus-navlink.active {
    background-color: #0eb7d1 !important;
    color: rgb(255, 255, 255) !important;
}

.by-nav-item {
    width: 50%;
}

.buysell-card {
    background-color: #233243;
}

.buy-card-header {
    background-color: #fff;
    color: rgb(83, 82, 82);
}

.buy-card-header p {
    margin-bottom: 0;
    text-align: center;
    font-weight: 600;
    text-transform: capitalize;

}

/* buyform */
.buyform {
    position: relative;
    width: 500px;
    margin: auto;


}

.cusbuy-form {
    background: transparent;
    color: #c4c4c4 !important;
    border-color: #ffffff96;
}

.cusbuy-form:focus {
    box-shadow: none;
    background: transparent;
    color: #fff;
    border-color: #ffffff96;
}

.form-control:disabled,
.form-control[readonly] {
    background-color: transparent !important;
    opacity: 1;
}


.persent-btn {
    padding: 4px 25px;
    background-color: #0eb7d1;
    color: #fff;
    border: 0;
    border-radius: 5px;
    height: 33px;
    margin-right: 10px
}

.persent-btn:hover {
    color: #fff;
    background-color: #0eb7d1f3;
}

.persent-btn.active {
    background-color: #fff;
    color: #0eb7d1;
}

.persent-btn:focus {
    box-shadow: none;
}

/* bottm btn */
.bottom-btn-box {
    background-color: #00000000;
    position: fixed;
    top: 93%;
    /* bottom: 0; */
    width: 100%;
    z-index: 1000;
    /* display: none; */

}


.bottom-btn {
    padding: 5px 20px;
    background-color: red;
    color: #fff;
    width: 100%;

}

.botton-buy-btn {
    padding: 5px 20px;
    background-color: green;
    color: #fff;
    width: 100%;
    margin: auto;
}

.botton-sell-btn {
    padding: 5px 20px;
    background-color: red;
    color: #fff;
    width: 100%;
}



.sidebarmarketplace {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    width: 120px;
    height: 100%;
    background-color: rgb(255, 255, 255);
    padding: 20px 4px;
    overflow: auto;
    transition: all 0.3s;
    
}

.currency-sl-btn {
    padding: 4px 10px;
    background-color: #fff !important;
    color: black !important;
}

.currency-sl-btn img {
    width: 20px;
    height: 20px;
}

.currency-box img {
    width: 17px;
    height: 17px;
    margin: 0px 5px;
}

.currency-box {
    display: flex;
    color: black;
    align-items: center;
    /* justify-content: center; */
    cursor: pointer;
    margin-bottom: 20px;
    font-size: 12px;
}

.currency-box:hover {
    background-color: #e9e9e9;
    color: #5e5e5e;
}

.refreshbtn {
    display: inline-block;
    padding: 5px 7px;
    background-color: #fff;
    color: black;
}

/* .hestory-btm-box {
    color: black;
    display: flex;
}


.hestory-value-box {
    color: black;
} */

/* position: fixed;
top: 50%;
-webkit-transform: translateY(-50%);
-ms-transform: translateY(-50%);
transform: translateY(-50%); */

.data-card {
    width: 100%;
    height: 390px;
    overflow-y: scroll;
    margin-bottom: 40px;
    overflow-x: hidden;
}

.data-header {
    color: #ffffff;
    background-color: #0eb7d1;
    justify-content: center;
    display: flex;
    align-items: center;
}

.data-header p {
    margin-bottom: 0;
}

.table-data {
    display: inline-block;
    font-size: 12px;
}

.within-data {
    box-shadow: 0px 0px 10px 0px rgb(184, 184, 184);
}


@media screen and (max-width: 700px) {
    .percent-div {
        /* justify-content: space-between; */
        display: flex;
        justify-content: space-between;
    }

    .bottom-btn-box {
        background-color: #000000;
        position: fixed;
        top: 93%;
        /* bottom: 0; */
        width: 100%;
        height: 60px;
        z-index: 1000;
        display: block;
        padding: 5px 10px;

    }

    .botton-buy-btn {
        padding: 5px 20px;
        background-color: green;
        color: #fff;
        width: 100%;
    }

    .botton-sell-btn {
        padding: 5px 20px;
        background-color: red;
        color: #fff;
        width: 100%;
    }

    .bottom-history-btn {
        padding: 5px 20px;
        background-color: #0eb7d1;
        color: #fff;
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .percent-div {
        /* justify-content: space-between; */
        display: flex;
        justify-content: space-between;
    }

    .table {
        white-space: nowrap;
        cursor: pointer;
        font-size: 12px;
    }

    .bottom-btn-box {
        background-color: #000000;
        position: fixed;
        top: 93%;
         bottom: 0;
        width: 100%;
        height: 60px;
        z-index: 1000;
        display: block;
        padding: 5px 10px;

    }

    .botton-buy-btn {
        padding: 5px 20px;
        background-color: green;
        color: #fff;
        width: 100%;
    }

    .botton-sell-btn {
        padding: 5px 20px;
        background-color: red;
        color: #fff;
        width: 100%;
    }

    .bottom-history-btn {
        padding: 5px 20px;
        background-color: #0eb7d1;
        color: #fff;
        width: 100%;
    }


    .buyform {
        position: relative;
        width: 100%;
        margin: auto;
        padding: 10px;


    }

    .persent-btn {
        padding: 5px 20px;
        background-color: #0eb7d1;
        color: #fff;
        border: 0;
        border-radius: 5px;
        height: 33px;
        /* margin-right: 10px; */
    }

    span#passwordHelpInline {
        font-size: 12px;
    }

    .col-form-label {
        font-size: 12px;
    }

}