.kyc-card {
    margin: 20px 0px;
}

.kyc-header {
    margin: 10px;
    text-align: center;

}

.kyc-cardbody h4 {
    text-align: center;
    color: rgb(110, 109, 109);
}

/* file */
h3 {
    margin-left: 15px
}


.fileInput {
    border-bottom: 4px solid lightgray;
    border-right: 4px solid lightgray;
    border-top: 1px solid black;
    border-left: 1px solid black;
    padding: 10px;
    margin: 15px;
    cursor: pointer;

}


.imgPreview {
    text-align: center;
    /* margin: 5px 15px; */
    height: 200px;
    width: 100%;
    border-left: 1px solid gray;
    border-right: 1px solid gray;
    border-top: 5px solid gray;
    border-bottom: 5px solid gray;
}

.imgPreview img {
    width: 1000%;
    height: 100%;
}


.previewText {
    width: 100%;
    margin-top: 20px;
}


.submitButton {
    padding: 12px;
    margin-left: 10px;
    background: white;
    border: 4px solid lightgray;
    border-radius: 15px;
    font-weight: 700;
    font-size: 10pt;
}

cursor:pointer &:hover {
    background: #efefef;
}


.centerText {
    text-align: center;
    /* width: 500px; */
}