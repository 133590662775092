/* body{
    background-color: red; */

    .user-header{
        background-color: rgb(245, 245, 245);
        padding: 10px;
    }
    .username-l{
        display: flex;
    }
    .username-l p{
        margin: 0px 10px;
    }.username-l span{
        color:#0eb7d1;
    }
    .userbox-btn{
        display: flex;
        justify-content: center;
        /* margin: 0px 0px; */
    }
    .user-card-body{
        display: flex;
        /* align-items: center; */
        /* align-items: baseline; */
    }
    .user-card-body span{
      color: red;
      margin: 0px 10px;
    }
    .link-card a{
        color: #0eb7d1;
        border-bottom: 1px solid rgb(189, 189, 189);
        margin: 10px 0px;
    }
    .link-card a::after{
        content: "\f054"; /* FontAwesome Unicode */
        font-family: FontAwesome;
        display: inline-block;
        margin-left:20px;
        width:12px;
        float: right;
       

    }
    .ref-box-icon{
     padding: 10px;
     text-align: center;
     margin-bottom: 10px;
    }
    .ref-box-icon i{
        color: #0eb7d1;
        font-size:60px;
        margin-bottom: 10px;
    }
    .link-card p{
        margin-bottom: 0;
    }

    @media screen and (max-width: 600px) {
        .link-card p{
            margin-bottom: 0;
            font-size: 12px;
        }
        .userbox-btn{
            margin: 10px 0px;
        }
      }