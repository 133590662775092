.news-heading-box {
    /* display: flex;
    align-items: center; */

    /* display: inline-block; */
    vertical-align: baseline;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
    margin: 6px 0px;

}

.news-heading-box i {
    color: #032c62;
}