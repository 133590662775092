*{
    margin:0;
    padding: 0;
    box-sizing: border-box;
    font-family: sans-serif;
}
.logbox{
   background-color:#eee;
   position:relative;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   width: 500px;
   padding: 40px 20px;
   border-radius:2px;
}
.logbox h3{
   text-align: center;
   color: #8c8c8c;
   /* padding: 30px 0px; */
}
.logbg{
   background-color:rgb(25 25 25);
   min-height:70vh;
   position:relative;  
   
}

.cus-formcontrol:focus{
box-shadow: none;
border-color: rgb(175, 175, 175);
}
/* .cus-formcontrol{
    padding: 10px !important;
} */
.linkbox a{
   color:#595959;
}
.form-btn{
   color: #fff;
     background-color:#0eb7d1;
}
.form-btn:hover{
   color: #fff;
     background-color:#0eb7d1ef;
}

/* media qaury/ */

@media screen and (max-width:600px) {
   .logbox{
       background-color:#eee;
       position:relative;
       top: 50%;
       left: 50%;
       transform: translate(-50%, -50%);
       width:100%;
       padding: 40px 20px;
       border-radius:2px;
   }
 }