#error {
    font-size: 10em;
}

img.displayed {
    display: block;
    margin-left: auto;
    margin-right: auto;
    height: 50%;
    width: 50%;
}

.error-head {
    font-weight: bold;
    font-size: 3.75em;
    color: #ee6052;
    margin-top: 50px;
}

.btn-back {
    padding: 4px 25px;
    background-color: #0eb7d1;
    color: #fff;
    border: 0;
    border-radius: 5px;
    height: 33px;
    margin-right: 10px;
}

@media (max-width: 980px) {
    #error {
        text-align: center;
        font-size: 12em;
    }

    img.displayed {
        height: 100%;
        width: 100%;
    }
}

@media (max-width: 320px) {
    #error {
        text-align: center;
        font-size: 11em;
    }

    img.displayed {
        height: 30%;
        width: 30%;
    }
}