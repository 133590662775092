.haverContain {
    position: absolute;
    top: 20px;
    /* left: 70px; */
    z-index: 20;
    color: black;
    font-size: 10px
}

.cus-select {
    position: absolute;
    top: 30px;
    right: 142px;
    z-index: 20;
    color: black;
    font-size: 10px;
    width: 80px;
}